<template>
  <div>
    <b-modal
      id="edit-rider-modal"
      title="BootstrapVue"
      size="lg"
      hide-header
      hide-footer
      no-close-on-backdrop
      scrollable
      centered
      content-class="edit-rider-modal"
      @hide="resetForm"
    >
      <span
        class="close-btn shadow-sm"
        @click="$bvModal.hide('edit-rider-modal')"
        @reset="resetForm"
      >
        <i class="fa fa-times"></i>
      </span>
      <h3 class="modal-title">Edit Baskytor</h3>
      <span
        class="d-none justify-content-center text-danger"
        :class="hasError"
        >{{ processingError }}</span
      >
      <b-form @submit="sendEditRider">
        <div class="row py-4">
          <div class="col-md-6">
            <div class="image-upload">
              <label for="rider-iamge-input" class="image-upload-label">
                <template v-if="this.profileImage !== null">
                  <img
                    class="rider-image"
                    :src="this.profileImage"
                    alt="user image"
                  />
                </template>
                <template v-else-if="this.profileImage === null">
                  <span class="icon">
                    <i class="fa fa-camera"></i>
                  </span>
                </template>

                <span class="text">Baskytor Image</span>
              </label>
              <input
                @change="uploadFile"
                type="file"
                name="riderImage"
                id="rider-iamge-input"
                class="mt-3"
                ref="riderImageRef"
              />
            </div>
          </div>
        </div>

        <div class="row py-4">
          <div class="col-md-6">
            <label for="form-name">Name</label>
            <b-form-input
              id="form-name"
              v-model="form.name"
              placeholder="Name"
            ></b-form-input>
          </div>
          <div class="col-md-6">
            <label for="form-phone">Contact#</label>
            <vue-tel-input
              disabledFetchingCountry
              :onlyCountries="['pk']"
              enabledCountryCode
              :enabledFlags="false"
              validCharactersOnly
              :maxLen="12"
              required
              defaultCountry="PK"
              v-model="phone"
              :disabledFormatting="true"
            ></vue-tel-input>
          </div>
        </div>
        <div class="row py-4">
          <div class="col-md-6">
            <label for="form-address">Address</label>
            <ValidateAddress
              :addressPassed="form.address"
              @sendAddress="recieveAddress"
              ref="validateRef"
            />
            <!-- from api need to add address and the will proceed further -->
          </div>
          <div class="row py-4">
            <div class="col-md-12">
              <label for="form-ssn">National Id Card</label>
              <b-form-input
                v-model="form.cnic"
                type="text"
                pattern="\b\d{5}[-]\d{7}[-]\d{1}\b"
                placeholder="CNIC (12345-6789012-3)"
              ></b-form-input>
            </div>
          </div>
        </div>
        <div class="row py-4">
          <div class="col-md-6">
            <label for="form-automobile-name">Bike Company</label>
            <b-form-select
              required
              v-model="form.bikeManufacturer"
              :options="autoManufacturersPK"
            ></b-form-select>
          </div>
          <div class="col-md-6">
            <label for="form-automobile-model">Bike Model</label>
            <b-form-input
              required
              v-model="form.bikeModel"
              placeholder="Auto Mobile Model"
            ></b-form-input>
          </div>
        </div>
        <div class="row py-4">
          <div class="col-md-6">
            <label for="form-automobile-make">Bike Year</label>
            <b-form-select
              v-model="form.bikeYear"
              :options="autoMobileYearOptions"
            ></b-form-select>
          </div>
          <div class="col-md-6">
            <label for="form-automobile-number">Bike Plate Number</label>
            <b-form-input
              required
              v-model="form.bikePlateNumber"
              placeholder="Automobile License Plate Number"
            ></b-form-input>
          </div>
        </div>
        <!-- addding data below -->
        <div class="row py-4">
          <div class="col-md-6">
            <label for="form-email">Refrence Name</label>
            <b-form-input
              required
              v-model="form.referenceName"
              placeholder="Refrence Name"
              type="text"
            ></b-form-input>
          </div>
          <div class="col-md-6">
            <label for="form-email">Refrence Contact</label>
            <b-form-input
              required
              v-model="form.referenceContact"
              placeholder="Refrence Contact"
              type="text"
            ></b-form-input>
          </div>
        </div>

        <div class="row py-4">
          <div class="col-md-6">
            <label for="form-email">Refrence Relation</label>
            <b-form-input
              required
              v-model="form.referenceRelation"
              placeholder="Refrence Name"
              type="text"
            ></b-form-input>
          </div>
          <div class="col-md-6">
            <label for="form-email">Email</label>
            <b-form-input
              v-model="form.email"
              placeholder="xyz@abc.com"
              type="email"
            ></b-form-input>
          </div>
        </div>
        <div class="row py-4">
          <div class="col-md-6">
            <label for="form-automobile-make">Starting Masterzone</label>
            <b-form-select
              v-model="form.masterZone"
              text-field="alias"
              value-field="_id"
              :options="masterZoneOptions"
              @change="masterZoneChange"
              required
            ></b-form-select>
          </div>
          <div class="col-md-6">
            <label for="form-automobile-make">Starting Subzone</label>
            <b-form-select
              v-model="form.startingZone"
              :options="subZoneOptions"
              text-field="alias"
              value-field="_id"
              :disabled="masterZone === null && subZoneOptions.length < 1"
            ></b-form-select>
          </div>
        </div>
        <div class="row py-4">
          <div class="col-md-6">
            <label for="form-name">Starting Time</label>
            <b-form-timepicker
              locale="en"
              v-model="form.startTime"
              ref="startTimeRef"
              required
              class="mb-2"
              :class="startTimeError"
            ></b-form-timepicker>
          </div>
          <div class="col-md-6">
            <label for="form-name">Ending Time</label>
            <b-form-timepicker
              locale="en"
              v-model="form.endTime"
              required
              class="mb-2"
              ref="endTimeRef"
              :class="endTimeError"
            ></b-form-timepicker>
          </div>
        </div>
        <!-- adding data above -->
        <div class="row p-4 d-flex flex-column">
          <label for="form-name">Item</label>
          <b-form-group>
            <b-form-checkbox-group
              v-model="form.merchandise"
              :options="riderOptions"
              plain
            ></b-form-checkbox-group>
          </b-form-group>
        </div>
        <div class="row py-4">
          <div class="col-md-6">
            <div class="image-upload" :class="uploadAttachError">
              <label for="rider-doc-img" class="image-upload-label">
                <span class="icon">
                  <i class="fa fa-camera"></i>
                </span>
                <span class="text">Upload Atachment</span>
              </label>
              <input
                @change="uploadAttach"
                type="file"
                name="docImage"
                id="rider-doc-img"
                class="mt-3"
                ref="docImageRef"
              />
            </div>
          </div>
        </div>

        <div class="row d-flex justify-content-end p-4">
          <b-button
            variant="danger"
            type="Submit"
            class="create-button"
            :class="processingRequest"
            :disabled="processingRequest !== ''"
            >Update
            <img
              class="ajax-loader"
              src="/media/ajax/white.gif"
              alt="Ajax loader"
          /></b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import ValidateAddress from "@/view/pages/riders/ValidateAddress";

export default {
  props: {
    riderToEdit: Object
  },
  components: {
    ValidateAddress
  },
  data() {
    return {
      googleAddress: null,
      form: {
        name: "JPMorgan Chase & Co.",
        phone: "",
        address: "",
        ssn: "",
        accountTitle: "",
        accountNumber: "",
        bankName: "",
        merchandise: [],
        userType: "RIDER",
        stripeCardNumber: "9999-9999",
        stripeCardPin: 2562,
        zipCode: [],
        autoMobileModel: "",
        autoMobileYear: "",
        autoMobileNumber: "",
        cnic: "",
        referenceName: "",
        referenceContact: "",
        referenceRelation: "",
        startTime: "",
        endTime: "",
        autoMobileName: "",
        autoMobileMake: "2020",
        docId: "",
        email: "",
        masterZone: "",
        startingZone: "",
        userId: ""
      },
      autoMobileYearOptions: [],
      riderImage: null,
      docImage: null,
      phone: "",
      riderOptions: [
        { text: "Bag", value: "bag" },
        { text: "Jacket", value: "jacket" },
        { text: "Shirt", value: "shirt" },
        { text: "Raincoat", value: "raincoat" }
      ],
      uploadAttachError: false,
      processingRequest: "",
      hasError: false,
      processingError: "",
      autoManufacturersPK: [
        "Aprilia",
        "Benelli",
        "Chinese Bikes",
        "Crown",
        "Derbi",
        "DYL",
        "Eagle",
        "FUEGO",
        "Ghani",
        "Hero",
        "Hi Speed",
        "Honda",
        "KEEWAY",
        "Lifan",
        "Metro",
        "Osaka",
        "OVERDRIVE",
        "OW",
        "Pak Hero",
        "Power",
        "QINGQI",
        "Ravi",
        "Road Prince",
        "Sohrab",
        "Super Asia",
        "Super Power",
        "Suzuki",
        "U.M",
        "Unique",
        "United",
        "Vespa",
        "Yamaha",
        "Zongshen",
        "Zontes",
        "ZXMCO"
      ],
      autoManufacturersUS: [
        "Abarth",
        "Alfa Romeo",
        "Aston Martin",
        "Audi",
        "Bentley",
        "BMW",
        "Bugatti",
        "Cadillac",
        "Chevrolet",
        "Chrysler",
        "Citroën",
        "Dacia",
        "Daewoo",
        "Daihatsu",
        "Dodge",
        "Donkervoort",
        "DS",
        "Ferrari",
        "Fiat",
        "Fisker",
        "Ford",
        "Honda",
        "Hummer",
        "Hyundai",
        "Infiniti",
        "Iveco",
        "Jaguar",
        "Jeep",
        "Kia",
        "KTM",
        "Lada",
        "Lamborghini",
        "Lancia",
        "Land Rover",
        "Landwind",
        "Lexus",
        "Lotus",
        "Maserati",
        "Maybach",
        "Mazda",
        "McLaren",
        "Mercedes-Benz",
        "MG",
        "Mini",
        "Mitsubishi",
        "Morgan",
        "Nissan",
        "Opel",
        "Peugeot",
        "Porsche",
        "Renault",
        "Rolls-Royce",
        "Rover",
        "Saab",
        "Seat",
        "Skoda",
        "Smart",
        "SsangYong",
        "Subaru",
        "Suzuki",
        "Tesla",
        "Toyota",
        "Volkswagen",
        "Volvo"
      ],
      bankOptions: [
        "JPMorgan Chase & Co.",
        "Bank of America Corp.",
        "Wells Fargo & Co.",
        "Citigroup Inc.",
        "U.S. Bancorp",
        "Truist Bank",
        "PNC Financial Services Group Inc.",
        "TD Group US Holdings LLC",
        "Capital One Financial Corp.",
        "Bank of New York Mellon Corp.",
        "Goldman Sachs Group Inc.",
        "State Street Corp.",
        "HSBC",
        "Fifth Third Bank",
        "Citizens Financial Group"
      ],
      masterZoneOptions: [],
      masterZone: null,
      subZoneOptions: [],
      profileImage: null,
      startTimeError: "border-light",
      endTimeError: "border-light"
    };
  },
  methods: {
    selectedGoogleAddress({ address }) {
      this.googleAddress = address;
      if (this.googleAddress === null) return;
      else {
        this.form.address = this.googleAddress.address;
        console.log(this.form.address);
      }
    },
    async getMasterZones() {
      const { data } = await ApiService.get("/zones/getOperating/master-zone");

      const { zones } = data;
      if (!this.form.masterZone) {
        this.form.masterZone = zones[0]._id;
      }
      const temp = {
        _id: null,
        alias: "Please Select the Zone"
      };
      this.masterZoneOptions = [temp, ...zones];
    },
    async masterZoneChange(val) {
      console.log(
        "file: EditRider.vue | line 697 | masterZoneChange | val",
        val
      );
      this.masterZone = val;
      if (val !== null) await this.getSubZones();
    },
    async getSubZones() {
      const { data } = await ApiService.get(
        `/zones/getOperating/sub-zone?parent=${this.form.masterZone}`
      );
      const { zones } = data;
      const temp = {
        _id: null,
        alias: "Sub Zone"
      };
      this.subZoneOptions = [temp, ...zones];
    },
    showModal: function() {
      this.$bvModal.show("edit-rider-modal");
    },
    closeModal: function() {
      this.$bvModal.hide("edit-rider-modal");
    },
    uploadFile: function() {
      this.riderImage = this.$refs.riderImageRef.files[0];
    },
    uploadAttach: function() {
      this.docImage = this.$refs.docImageRef.files[0];
    },
    resetForm: function() {
      this.$emit("close");
    },
    recieveAddress: function(obj) {
      this.form.address = obj.address;
      this.form.zipCode = obj.zipCode;
      this.city = obj.city;
      this.country = obj.country;
      this.state = obj.state;
      this.line1 = obj.line1;
      this.line2 = obj.line2;
    },
    sendEditRider: function(e) {
      e.preventDefault();
      try {
        const tk = JwtService.getToken();
        this.form.token = tk;
        this.processingRequest = "processing";
        // if (!this.phone.includes("+1")) this.form.phone = "+1" + this.phone;
        if (this.phone !== null) {
          const uc = this.phone
            .replace(/\s/g, "")
            .replace("(", "")
            .replace(")", "");
          this.form.phone = uc.startsWith("+92")
            ? `+${parseInt(uc.replace(/-/g, ""), 10)}`
            : `+92${parseInt(uc.replace(/-/g, ""), 10)}`;
        }
        ApiService.post("/rider/editRider", this.form)
          .then(res => {
            res = res.data;

            if (!res.success) {
              this.hasError = "d-flex";
              this.processingError = res.message;
              this.processingRequest = "";
              setTimeout(() => {
                this.hasError = "d-none";
                this.processingError = "";
              }, 3000);
            }
            if (this.riderImage !== null) {
              ApiService.setHeader("content-type", "multipart/form-data");
              const formData = new FormData();
              formData.append("image", this.riderImage);
              formData.append("riderId", this.riderToEdit._id);

              ApiService.post("/rider/uploadImage", formData)
                .then(res1 => {
                  const newFormData = new FormData();
                  newFormData.append("document", this.docImage);
                  newFormData.append("riderId", this.riderToEdit._id);
                  if (res1.data.success) {
                    if (this.docImage !== null) {
                      ApiService.post(
                        "/rider/uploadDocument",
                        newFormData
                      ).then(res2 => {
                        if (res2.data.success) {
                          this.processingRequest = "";
                          this.$emit("riderEdited");
                        }
                      });
                    } else {
                      this.processingRequest = "";
                      this.$emit("riderEdited");
                    }
                  }
                })
                .catch(({ data }) => {
                  this.hasError = "d-flex";
                  this.processingError = data.message + " on " + data.url;
                  this.processingRequest = "";
                  setTimeout(() => {
                    this.hasError = "d-none";
                    this.processingError = "";
                  }, 3000);
                });
            } else {
              this.processingRequest = "";
              this.$emit("riderEdited");
            }
          })
          .catch(({ data }) => {
            this.hasError = "d-flex";
            this.processingError = data.message + " on " + data.url;
            this.processingRequest = "";
            setTimeout(() => {
              this.hasError = "d-none";
              this.processingError = "";
            }, 3000);
          });
      } catch (error) {
        this.processingRequest = "";
        this.$emit("riderEdited");
        console.log("Console Log: : error", error);
      }
    },
    ssnChange: function(val) {
      this.form.docId = val;
    }
  },

  async mounted() {
    let arr = [];
    const currentYear = new Date().getFullYear();
    for (let i = 1988; i <= currentYear; ++i) {
      arr.push({ text: `${i}`, value: i });
    }
    this.autoMobileYearOptions = arr;
    const val = this.riderToEdit;
    this.form.name = val.name;
    let tempPhone = val.phone;
    this.phone = tempPhone.replace("+92", "0");
    this.profileImage = val.profileImage;
    this.form.bikeModel = val.bikeModel;
    this.form.bikeYear = val.bikeYear;
    this.form.bikePlateNumber = val.bikePlateNumber;
    this.form.bikeManufacturer = val.bikeManufacturer;
    this.form.masterZone = val.masterZone;
    this.form.startingZone = val.startingZone._id || null;
    this.form.referenceName = val.referenceName;
    this.form.referenceContact = val.referenceContact;
    this.form.referenceRelation = val.referenceRelation;
    this.form.startTime = val.startTime; //startTime as below starting masterzone
    this.form.endTime = val.endTime;
    this.form.merchandise = val.merchandise;
    this.form.cnic = val.cnic;
    await this.getMasterZones();
    await this.getSubZones();
    this.phone = tempPhone.replace("+1", "");

    this.form.address = val.address;
    this.form.ssn = val.ssn;
    this.form.accountTitle = val.accountTitle;
    this.form.accountNumber = val.accountNumber;
    this.form.bankName = val.bankName;
    this.form.merchandise = val.merchandise;
    this.form.userType = "RIDER";
    this.form.stripeCardNumber = val.stripeCardNumber;
    this.form.stripeCardPin = val.stripeCardPin;
    this.form.zipCode = val.zipCode;
    this.form.email = val.email;
    this.form.docId = val.docId;
    this.form.userId = val.userId;
    this.form.riderId = val._id;
  }
};
</script>

<style lang="scss" scoped>
::v-deep .modal-backdrop {
  background: #fff;
}
::v-deep .modal-dialog {
  width: 600px;
}
::v-deep .edit-rider-modal {
  background: #fff;
  position: relative;
  .close-btn {
    position: absolute;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    right: 15px;
    cursor: pointer;
    background: #aeacac;

    i {
      color: #fff;
      font-size: 14px;
    }
  }
  h3.modal-title {
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  .image-upload {
    position: relative;
    label {
      display: flex;
      align-items: center;
      span.icon {
        &.attachment {
          display: flex;
          .text {
            max-width: 100px;
            text-align: center;
          }
        }
        padding: 10px 20px;
        background: #fff;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-right: 10px;
      }
      span.text {
        font-weight: 700;
        font-weight: 12px;
        display: flex;
        align-items: center;
      }
      margin: 0;
      cursor: pointer;
    }
    input {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
    }
  }
  /* .form-group {
    .form-check {
    }
  } */
 
  input.form-control,
  select.custom-select {
    height: auto;
    padding: 5px 10px;
  }
  input.vti__control {
    box-shadow: none;
  }
  .rider-image {
    height: 55px;
    width: 55px;
    border-radius: 50%;
  }
}
</style>
