<template>
  <div class="archived-orders">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 py-3">
          <h3>Archived Baskytors</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-4 sorting-options">
          <b-form-group label="Sort Baskytors By">
            <b-form-radio-group
              class="option-styles"
              v-model="sortRiders"
              :options="sortRidersOptions"
              name="radio-inline"
              @change="changeSort"
            ></b-form-radio-group>
          </b-form-group>
        </div>
        <div
          class="col-4 d-flex flex-column justify-content-between align-items-center"
        >
          <b-form-group>
            <b-form-radio-group
              id="btn-radios-1"
              v-model="sortDirection"
              :options="sortDirectionOptions"
              buttons
              name="radios-btn-default"
              @change="changeDirection"
            ></b-form-radio-group>
          </b-form-group>
          <form @submit.prevent="searchRiders">
            <div class="row d-flex align-items-center">
              <div class="col-9">
                <b-input
                  type="text"
                  v-model="searchText"
                  placeholder="Search Rider"
                />
              </div>
              <div class="col-3">
                <button class="go-btn" type="submit">Go</button>
              </div>
            </div>
          </form>
        </div>
        <div
          class="col-4 d-flex align-items-center justify-content-end py-3"
          v-if="rows > 10"
        >
          <div class="row w-100">
            <span class="col-4 d-flex align-items-center"
              >Baskytors Per Page</span
            >
            <b-form-select
              v-model="perPage"
              :options="perPageOptions"
              class="col-8"
              @change="perPageChange"
            ></b-form-select>
          </div>
        </div>
      </div>
    </div>

    <div class="riders-section">
      <div class="overlay" v-if="riderFetchingOverlay">
        <div class="d-flex">
          <b-spinner variant="danger"></b-spinner>
          <span class="ml-4">Fetching...</span>
        </div>
      </div>
      <template v-else-if="!riderFetchingOverlay && networkError === null">
        <b-col
          cols="12"
          md="6"
          class="d-flex"
          :key="rider.updatedAt"
          v-for="(rider, index) in archivedRiders"
        >
          <SingleRider
            v-if="region === 'PAKISTAN'"
            :rider="rider"
            :isArchived="true"
            :index="index"
            @editRider="editRider"
            @update="updateRiders"
          />
          <SingleRider
            v-if="region === 'US'"
            :rider="rider"
            :isArchived="true"
            :index="index"
            @editRider="editRider"
          />
        </b-col>
      </template>
      <template v-else-if="!riderFetchingOverlay && networkError !== null">
        <div class="d-flex flex-column align-items-center w-100">
          <div class="text-danger text-center display-4 p-4">
            API Error
          </div>
          <div class="text-danger text-center lead pb-4">
            Error while fetchting archived Baskytors. Please refresh to try
            again.
          </div>
          <div class="text-danger text-center lead pb-4">
            {{ networkError }}
          </div>
        </div>
      </template>
    </div>
    <template v-if="rows > 0">
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        first-number
        @change="changePage"
      ></b-pagination>
    </template>
  </div>
</template>

<script>
import SingleRider from "@/view/pages/riders/SingleRider";
import { mapState, mapGetters } from "vuex";
import { GET_ALL_ARCHIVED_RIDERS } from "@/core/services/store/riders.module";
export default {
  props: {
    riders: Array
  },
  components: {
    SingleRider
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" }
      ],
      sortRiders: "sortByCreatedDate",
      sortRidersOptions: [
        { text: "Created", value: "sortByCreatedDate" },
        { text: "Ratings", value: "sortByAvgRating" },
        { text: "Total Orders", value: "sortByTotalOrders" },
        { text: "Avg. Order Delievery Time", value: "sortByAvgDeliveryTime" },
        { text: "Online Status", value: "sortByOnlineState" }
      ],
      sortDirection: 1,
      sortDirectionOptions: [
        {
          text: "Ascending",
          value: 2,
          html: '<i class="fa fa-sort-amount-up"/>'
        },
        {
          text: "Descending",
          value: 1,
          html: '<i class="fa fa-sort-amount-down"/>'
        }
      ],
      searchText: ""
    };
  },
  methods: {
    ...mapGetters(["archivedRiderAmount"]),
    changeSort: function(val) {
      let obj = {};
      obj[val] = this.sortDirection;
      obj.searchText = this.searchText;
      this.updateRiders(obj);
    },
    searchRiders: function() {
      let obj = {};
      obj[this.sortRiders] = this.sortDirection;
      obj.searchText = this.searchText;
      this.updateRiders(obj);
    },
    changeDirection: function(val) {
      let obj = {};
      obj[this.sortRiders] = val;
      obj.searchText = this.searchText;
      this.updateRiders(obj);
    },
    updateRiders(options = {}) {
      options[this.sortRiders] = this.sortDirection;
      options.searchText = this.searchText;
      options.limit = [
        (this.currentPage - 1) * this.perPage,
        (this.currentPage - 1) * this.perPage + this.perPage
      ];
      this.$store.dispatch(GET_ALL_ARCHIVED_RIDERS, options);
    },
    changePage() {
      let obj = {};
      obj[this.sortRiders] = this.sortDirection;
      obj.searchText = this.searchText;
      this.updateRiders(obj);
    },
    perPageChange() {
      let obj = {};
      obj[this.sortRiders] = this.sortDirection;
      obj.searchText = this.searchText;
      this.updateRiders(obj);
    },
    editRider: function(id) {
      const rider = this.archivedRiders[id];
      this.$emit("editRider", rider);
    }
  },

  mounted() {
    this.updateRiders();
  },

  computed: {
    ...mapState({
      region: state => state.region.isRegion,
      archivedRiders: function(state) {
        return state.riders.archivedRiders;
      },
      rows: state => state.riders.archivedCount,
      riderFetchingOverlay: state => state.riders.fetchingArchivedRiders,
      networkError: function(state) {
        return state.riders.archivedRidersError;
      }
    })
  }
};
</script>

<style lang="scss" scoped>
.archived-orders {
  display: flex;
  flex-direction: column;
  align-items: center;
  .riders-section {
    width: 100%;
    min-height: 75px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    .overlay {
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.5);
      position: absolute;
      display: flex;
      justify-content: center;
      padding: 25px;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }
  .go-btn {
    color: #fff;
    background: #d8102a;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 5px;
  }
  .sorting-options {
    .option-styles {
      display: grid;
      grid-template-columns: auto auto;
    }
    ::v-deep legend.col-form-label {
      font-weight: 600;
    }
  }
}
</style>
