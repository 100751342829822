<template>
  <div>
    <b-modal
      v-if="region === 'PAKISTAN'"
      id="new-rider-modal"
      title="BootstrapVue"
      size="lg"
      hide-header
      hide-footer
      no-close-on-backdrop
      scrollable
      centered
      content-class="new-rider-modal"
    >
      <span class="close-btn shadow-sm" @click="closeModal">
        <i class="fa fa-times"></i>
      </span>
      <h3 class="modal-title">New Baskytor</h3>
      <span
        class="d-none justify-content-center text-danger"
        :class="hasError"
        >{{ processingError }}</span
      >
      <b-form @submit.prevent="createUser">
        <div class="row py-4">
          <div class="col-md-6">
            <div class="image-upload">
              <label for="rider-iamge-input" class="image-upload-label">
                <span class="icon">
                  <i class="fa fa-camera"></i>
                </span>
                <span class="text">Baskytor Image</span>
              </label>
              <input
                @change="uploadFile"
                type="file"
                name="riderImage"
                id="rider-iamge-input"
                class="mt-3"
                ref="riderImageRef"
                required
              />
            </div>
          </div>
          <div
            class="
              col-md-6
              d-flex
              justify-content-end
              upload-image-preview
              align-items-center
            "
          >
            <template v-if="imageUrl !== null">
              <img :src="imageUrl" alt="Level image" />
            </template>
            <template v-else>
              <h6>No image added</h6>
            </template>
          </div>
        </div>
        <div class="row py-4">
          <div class="col-md-6">
            <label for="form-name">Name</label>
            <b-form-input
              id="form-name"
              required
              v-model="formPK.name"
              placeholder="Name"
            ></b-form-input>
          </div>
          <div class="col-md-6">
            <label for="form-phone">Contact#</label>
            <vue-tel-input
              disabledFetchingCountry
              :onlyCountries="['pk']"
              enabledCountryCode
              :enabledFlags="false"
              validCharactersOnly
              :maxLen="12"
              required
              defaultCountry="PK"
              v-model="formPK.phone"
            ></vue-tel-input>
          </div>
        </div>
        <!-- <b-form-input
              :v-model="formPK.address"
              placeholder="Address"
              type="text"
              required
            >
            </b-form-input> -->
        <div class="row py-4">
          <div class="col-md-6">
            <label for="form-address">Address</label>

            <google-search @selected="selectedGoogleAddress" />
          </div>
          <div class="col-md-6">
            <label for="form-ssn">National Id Card</label>
            <b-form-input
              required
              v-model="formPK.cnic"
              type="text"
              pattern="\b\d{5}[-]\d{7}[-]\d{1}\b"
              placeholder="CNIC (12345-124567-1)"
            ></b-form-input>
          </div>
        </div>

        <div class="row py-4">
          <div class="col-md-6">
            <label for="form-automobile-name">Motorbike Manufacturer</label>
            <b-form-select
              required
              v-model="formPK.bikeManufacturer"
              :options="autoManufacturersPK"
            ></b-form-select>
          </div>
          <div class="col-md-6">
            <label for="form-automobile-model">Motorbike Model</label>
            <b-form-input
              required
              v-model="formPK.bikeModel"
              placeholder="Auto Mobile Model"
            ></b-form-input>
          </div>
        </div>
        <div class="row py-4">
          <div class="col-md-6">
            <label for="form-automobile-make">Motorbike Year</label>
            <b-form-select
              v-model="formPK.bikeYear"
              :options="autoMobileYearOptions"
            ></b-form-select>
          </div>
          <div class="col-md-6">
            <label for="form-automobile-number">Motorbike Plate Number</label>
            <b-form-input
              required
              v-model="formPK.bikePlateNumber"
              placeholder="Automobile License Plate Number"
            ></b-form-input>
          </div>
        </div>
        <div class="row py-4">
          <div class="col-md-6">
            <label for="form-email">refernce Name</label>
            <b-form-input
              required
              v-model="formPK.referenceName"
              placeholder="refernce Name"
              type="text"
            ></b-form-input>
          </div>
          <div class="col-md-6">
            <label for="form-email">refernce Contact</label>
            <b-form-input
              required
              v-model="formPK.referenceContact"
              placeholder="refernce Contact"
              type="text"
            ></b-form-input>
          </div>
        </div>
        <div class="row py-4">
          <div class="col-md-6">
            <label for="form-email">refernce Relation</label>
            <b-form-input
              required
              v-model="formPK.referenceRelation"
              placeholder="refernce Name"
              type="text"
            ></b-form-input>
          </div>
          <div class="col-md-6">
            <label for="form-email">Email</label>
            <b-form-input
              v-model="formPK.email"
              placeholder="xyz@abc.com"
              type="email"
            ></b-form-input>
          </div>
        </div>
        <div class="row py-4">
          <div class="col-md-6">
            <label for="form-automobile-make">Starting Masterzone</label>
            <b-form-select
              v-model="formPK.masterZone"
              text-field="alias"
              value-field="_id"
              :options="masterZoneOptions"
              @change="masterZoneChange"
              required
            ></b-form-select>
          </div>
          <div class="col-md-6">
            <label for="form-automobile-make">Starting Subzone</label>
            <b-form-select
              v-model="formPK.startingZone"
              :options="subZoneOptions"
              text-field="alias"
              value-field="_id"
              required
              :disabled="formPK.masterZone === null && subZoneOptions.length"
            ></b-form-select>
          </div>
        </div>
        <div class="row py-4">
          <div class="col-md-6">
            <label for="form-name">Starting Time</label>
            <b-form-timepicker
              locale="en"
              v-model="formPK.startTime"
              ref="startTimeRef"
              required
              class="mb-2"
              :class="startTimeError"
            ></b-form-timepicker>
          </div>
          <div class="col-md-6">
            <label for="form-name">Ending Time</label>
            <b-form-timepicker
              locale="en"
              v-model="formPK.endTime"
              required
              class="mb-2"
              ref="endTimeRef"
              :class="endTimeError"
            ></b-form-timepicker>
          </div>
        </div>
        <div class="row p-4 d-flex flex-column">
          <label for="form-name">Item</label>
          <b-form-group>
            <b-form-checkbox-group
              v-model="formPK.merchandise"
              :options="riderOptions"
              plain
            ></b-form-checkbox-group>
          </b-form-group>
        </div>

        <div class="row py-4">
          <div class="col-md-6">
            <div class="image-upload" :class="uploadAttachError">
              <label for="driversLicenseFront" class="image-upload-label">
                <span class="icon">
                  <template v-if="documents.driversLicenseFront !== null">
                    <i class="fa fa-check"></i>
                  </template>
                  <template v-else>
                    <i class="fa fa-camera"></i>
                  </template>
                </span>
                <span class="text document">Driver's License Front</span>
              </label>
              <input
                @change="
                  uploadAttach('driversLicenseFrontRef', 'driversLicenseFront')
                "
                type="file"
                name="driversLicenseFront"
                id="driversLicenseFront"
                class="mt-3"
                ref="driversLicenseFrontRef"
              />
            </div>
          </div>

          <div class="col-md-6">
            <div class="image-upload" :class="uploadAttachError">
              <label for="driversLicenseBack" class="image-upload-label">
                <span class="icon">
                  <template v-if="documents.driversLicenseBack !== null">
                    <i class="fa fa-check"></i>
                  </template>
                  <template v-else>
                    <i class="fa fa-camera"></i>
                  </template>
                </span>
                <span class="text document">Driver's License Back</span>
              </label>
              <input
                @change="
                  uploadAttach('driversLicenseBackRef', 'driversLicenseBack')
                "
                type="file"
                name="driversLicenseBack"
                id="driversLicenseBack"
                class="mt-3"
                ref="driversLicenseBackRef"
              />
            </div>
          </div>
        </div>

        <div class="row py-4">
          <div class="col-md-6">
            <div class="image-upload" :class="uploadAttachError">
              <label for="driversCnicFront" class="image-upload-label">
                <span class="icon">
                  <template v-if="documents.driversCnicFront !== null">
                    <i class="fa fa-check"></i>
                  </template>
                  <template v-else>
                    <i class="fa fa-camera"></i>
                  </template>
                </span>
                <span class="text document">Driver's CNIC Front</span>
              </label>
              <input
                @change="
                  uploadAttach('driversCnicFrontRef', 'driversCnicFront')
                "
                type="file"
                name="driversCnicFront"
                id="driversCnicFront"
                class="mt-3"
                ref="driversCnicFrontRef"
              />
            </div>
          </div>

          <div class="col-md-6">
            <div class="image-upload" :class="uploadAttachError">
              <label for="driversCnicBack" class="image-upload-label">
                <span class="icon">
                  <template v-if="documents.driversCnicBack !== null">
                    <i class="fa fa-check"></i>
                  </template>
                  <template v-else>
                    <i class="fa fa-camera"></i>
                  </template>
                </span>
                <span class="text document">Driver's CNIC Back</span>
              </label>
              <input
                @change="uploadAttach('driversCnicBackRef', 'driversCnicBack')"
                type="file"
                name="driversCnicBack"
                id="driversCnicBack"
                class="mt-3"
                ref="driversCnicBackRef"
              />
            </div>
          </div>
        </div>
        <div class="row d-flex justify-content-end p-4 align-items-center">
          <span class="process-status"> {{ processingStatusText }}</span>
          <b-button
            variant="danger"
            type="Submit"
            class="create-button"
            :class="processingRequest"
            :disabled="processingRequest !== ''"
            >Create
            <img
              class="ajax-loader"
              src="/media/ajax/white.gif"
              alt="Ajax loader"
          /></b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import PlacesAutocomplete from "@/view/pages/riders/PlacesAutocomplete";
import { mapState } from "vuex";
export default {
  name: "NewRiderComponent",
  components: {
    "google-search": PlacesAutocomplete
  },
  computed: {
    ...mapState({
      region: state => state.region.isRegion
    })
  },

  data() {
    return {
      googleAddress: null,
      formPK: {
        name: "",
        phone: "",
        address: "",
        cnic: "",
        merchandise: [],
        userType: "RIDER",
        bikeManufacturer: "Honda",
        bikeModel: "",
        bikePlateNumber: "",
        bikeYear: "2020",
        email: "",
        referenceName: "",
        referenceContact: "",
        referenceRelation: "",
        startTime: "",
        endTime: "",
        startingZone: "",
        masterZone: null,
        subZone: ""
      },
      phone: "",
      imageUrl: null,
      autoMobileYearOptions: [],
      riderImage: null,
      documents: {
        driversLicenseFront: null,
        driversLicenseBack: null,
        driversCnicFront: null,
        driversCnicBack: null
      },
      riderOptions: [
        { text: "Bag", value: "bag" },
        { text: "Jacket", value: "jacket" },
        { text: "Shirt", value: "shirt" },
        { text: "Raincoat", value: "raincoat" }
      ],
      uploadAttachError: false,
      processingRequest: "",
      hasError: false,
      processingError: "",
      processingStatusText: "",
      city: "",
      country: "",
      state: "",
      line1: "",
      line2: "",
      autoManufacturersPK: [
        "Aprilia",
        "Benelli",
        "Chinese Bikes",
        "Crown",
        "Derbi",
        "DYL",
        "Eagle",
        "FUEGO",
        "Ghani",
        "Hero",
        "Hi Speed",
        "Honda",
        "KEEWAY",
        "Lifan",
        "Metro",
        "Osaka",
        "OVERDRIVE",
        "OW",
        "Pak Hero",
        "Power",
        "QINGQI",
        "Ravi",
        "Road Prince",
        "Sohrab",
        "Super Asia",
        "Super Power",
        "Suzuki",
        "U.M",
        "Unique",
        "United",
        "Vespa",
        "Yamaha",
        "Zongshen",
        "Zontes",
        "ZXMCO"
      ],
      masterZoneOptions: [],

      subZoneOptions: [],
      startTimeError: "border-light",
      endTimeError: "border-light"
    };
  },
  methods: {
    selectedGoogleAddress({ address }) {
      this.googleAddress = address;
      if (this.googleAddress === null) return;

      this.formPK.address = this.googleAddress.address;
    },
    validateAddressError(err) {
      this.hasError = "d-flex";
      this.processingError = err;
      this.processingStatusText = this.processingError;
      this.processingRequest = "";
      setTimeout(() => {
        this.hasError = "d-none";
        this.processingError = "";
      }, 3000);
    },
    createNewRider(e) {
      e.preventDefault();
      e.stopPropagation();
      this.createUser();
    },
    async getMasterZones() {
      const { data } = await ApiService.get("/zones/getOperating/master-zone");

      const { zones } = data;
      const temp = {
        _id: null,
        alias: "Please Select the Zone"
      };
      this.masterZoneOptions = [temp, ...zones];
    },
    async masterZoneChange(val) {
      if (val !== null) await this.getSubZones();
    },
    async getSubZones() {
      const { data } = await ApiService.get(
        `/zones/getOperating/sub-zone?parent=${this.formPK.masterZone}`
      );
      const { zones } = data;
      const temp = {
        _id: null,
        alias: "Sub Zone"
      };
      this.subZoneOptions = [temp, ...zones];
    },
    showModal() {
      this.$bvModal.show("new-rider-modal");
    },
    closeModal() {
      this.$bvModal.hide("new-rider-modal");
      this.$emit("closedModal");
    },
    uploadFile() {
      this.riderImage = this.$refs.riderImageRef.files[0];
      this.imageUrl = URL.createObjectURL(this.riderImage);
    },
    uploadAttach(ref, field) {
      this.documents[field] = this.$refs[ref].files[0];
    },
    createUser() {
      try {
        if (this.region === "PAKISTAN") {
          if (this.formPK.startTime === "") {
            this.startTimeError = "border-danger";
            this.$refs.startTimeRef.focus();
            return;
          } else {
            this.startTimeError = "border-success";
          }
          if (this.formPK.endTime === "") {
            this.endTimeError = " border-danger";
            this.$refs.endTimeRef.focus();
            return;
          } else {
            this.endTimeError = "border-success";
          }
          const tk = JwtService.getToken();
          this.formPK.token = tk;
        }
        if (this.region === "US") {
          if (this.formUS.startTime === "") {
            this.startTimeError = "border-danger";
            this.$refs.startTimeRef.focus();
            return;
          } else {
            this.startTimeError = "border-success";
          }
          if (this.formUS.endTime === "") {
            this.endTimeError = " border-danger";
            this.$refs.endTimeRef.focus();
            return;
          } else {
            this.endTimeError = "border-success";
          }
          const tk = JwtService.getToken();
          this.formUS.token = tk;
        }
        this.processingRequest = "processing";
        this.processingStatusText = "Creating user record ...";
        if (this.region === "US") {
          this.formUS.phone = "+1" + this.formUS.phone;
          ApiService.post("/user/addUser", this.formUS)
            .then(res => {
              res = res.data;
              if (res.status === "Duplicate") {
                this.hasError = "d-flex";
                this.processingError = res.data;
                this.processingStatusText = this.processingError;
                this.processingRequest = "";
                setTimeout(() => {
                  this.hasError = "d-none";
                  this.processingError = "";
                }, 3000);
                return;
              }
              const { user } = res;
              if (this.region === "PAKISTAN") {
                this.uploadImage(user);
              } else {
                this.uploadImageUS(user, this.uploadDocuments);
              }
            })
            .catch(({ data }) => {
              this.setError(`New Baskytor: ${data.message} on ${data.url}.`);
            });
        } else {
          ApiService.post("/user/addUser", this.formPK)
            .then(res => {
              res = res.data;
              if (res.status === "Duplicate") {
                this.hasError = "d-flex";
                this.processingError = res.data;
                this.processingStatusText = this.processingError;
                this.processingRequest = "";
                setTimeout(() => {
                  this.hasError = "d-none";
                  this.processingError = "";
                }, 3000);
                return;
              }
              const { user } = res;
              if (this.region === "PAKISTAN") {
                this.uploadImage(user);
              } else {
                this.uploadImageUS(user, this.uploadDocuments);
              }
            })
            .catch(({ data }) => {
              this.setError(`New Baskytor: ${data.message} on ${data.url}.`);
            });
        }
      } catch (error) {
        console.log("Console Log: : error", error);
      }
    },
    uploadImage(user) {
      console.log("uploadImage | user", user);
      if (this.riderImage !== null) {
        const formData = new FormData();
        formData.append("image", this.riderImage);
        formData.append("riderId", user.userDetail);
        this.processingStatusText = "Uploading Baskytor image ...";
        ApiService.post("/rider/uploadImage", formData)
          .then(res1 => {
            if (res1.data.success) {
              this.uploadDocuments(user);
            }
          })
          .catch(({ data }) => {
            this.setError(`Upload Image: ${data.message} on ${data.url}.`);
            ApiService.setHeader("content-type", "application/JSON");
          });
      }
    },
    uploadImageUS(user) {
      console.log("uploadImageUS", user);

      if (this.riderImage !== null) {
        const formData = new FormData();
        formData.append("image", this.riderImage);
        formData.append("riderId", user.userDetail);
        this.processingStatusText = "Uploading Baskytor image ...";
        ApiService.post("/rider/uploadImage", formData)
          .then(res1 => {
            if (res1.data.success) {
              console.log("image upload success");
              // next(user, this.createCardholder);
              this.createCardholder(user);
            }
          })
          .catch(({ data }) => {
            this.setError(`Upload Image: ${data.message} on ${data.url}.`);
            ApiService.setHeader("content-type", "application/JSON");
          });
      }
    },

    uploadDocumentsUS(user) {
      console.log("uploadDocumentsUS", user);

      const newFormData = new FormData();
      Object.entries(this.documents).forEach(([key, value]) => {
        newFormData.append(key, value);
      });
      newFormData.append("riderId", user.userDetail);
      this.processingStatusText = "Uploading Baskytor document ...";
      ApiService.post("/rider/uploadDocument", newFormData)
        .then(res2 => {
          ApiService.setHeader("content-type", "application/JSON");
          if (res2.data.success) {
            ApiService.setHeader("content-type", "application/JSON");
            // next(user, this.createCard);
            this.createCard(user);
          }
        })
        .catch(({ data }) => {
          this.setError(`Upload Document: ${data.message} on ${data.url}.`);
          ApiService.setHeader("content-type", "application/JSON");
        });
    },
    uploadDocuments(user) {
      const newFormData = new FormData();
      Object.entries(this.documents).forEach(([key, value]) => {
        newFormData.append(key, value);
      });
      newFormData.append("riderId", user.userDetail);
      this.processingStatusText = "Uploading Baskytor document ...";
      ApiService.post("/rider/uploadDocument", newFormData)
        .then(res2 => {
          ApiService.setHeader("content-type", "application/JSON");
          if (res2.data.success) {
            ApiService.setHeader("content-type", "application/JSON");
            this.processingRequest = "";
            this.$emit("addNewRider", user.password);
          }
        })
        .catch(({ data }) => {
          this.setError(`Upload Document: ${data.message} on ${data.url}.`);
          ApiService.setHeader("content-type", "application/JSON");
        });
    },
    setError(val) {
      this.hasError = "d-flex";
      this.processingError = val;
      this.processingStatusText = val;
      setTimeout(() => {
        this.processingRequest = "";
        this.hasError = "d-none";
        this.processingError = "";
      }, 5000);
    }
  },
  async mounted() {
    let arr = [];
    const currentYear = new Date().getFullYear();
    console.log(
      "🚀 ~ file: NewRider.vue ~ line 1371 ~ mounted ~ currentYear",
      currentYear
    );
    if (this.region === "PAKISTAN") {
      this.formPK.bikeYear = currentYear;
    }

    for (let i = 1988; i <= currentYear; ++i) {
      arr.push({ text: `${i}`, value: i });
    }
    this.autoMobileYearOptions = arr;
    if (this.region === "PAKISTAN") {
      await this.getMasterZones();
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .modal-backdrop {
  background: #fff;
}
::v-deep .modal-dialog {
  width: 600px;
}
::v-deep .new-rider-modal {
  background: #fff;
  position: relative;
  .close-btn {
    position: absolute;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    right: 15px;
    cursor: pointer;
    background: #aeacac;
    i {
      color: #fff;
      font-size: 14px;
    }
  }
  h3.modal-title {
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  .upload-image-preview {
    img {
      width: 45px;
      height: 45px;
      border-radius: 50%;
    }
  }
  .image-upload {
    position: relative;
    cursor: pointer;
    label {
      pointer-events: none;
      display: flex;
      align-items: center;

      span.icon {
        pointer-events: none;
        &.attachment {
          display: flex;
          .text {
            max-width: 100px;
            text-align: center;
          }
        }
        padding: 10px 20px;
        background: #fff;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-right: 10px;
      }
      span.text {
        cursor: pointer;
        font-weight: 12px;
        display: flex;
        align-items: center;
        font-weight: 700;
        &.document {
          font-weight: 600;
        }
      }

      margin: 0;
      cursor: pointer;
    }
    input {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      z-index: 0;
    }
  }
 
  .process-status {
    font-size: 12px;
    padding: 0 5px;
  }
  input.form-control,
  select.custom-select {
    height: auto;
    padding: 5px 10px;
  }
  input.vti__control {
    box-shadow: none;
  }
}
</style>
