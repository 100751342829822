<template>
  <div class="single-rider shadow-sm">
    <div class="upper-block" v-bind:class="getOverlayClass()">
      <div
        class="approved-status"
        :class="
          rider !== undefined && rider.pending ? 'non-approved' : 'approved'
        "
      >
        {{ rider !== undefined && rider.pending ? "Pending" : "Active" }}
      </div>
      <div class="rider-profile">
        <div class="user-block">
          <div class="user-image" style="position: relative">
            <span
              class="online-status"
              :class="
                rider !== undefined && rider.onlineState
                  ? rider.awayState
                    ? 'away'
                    : 'online'
                  : 'offline'
              "
            >
            </span>
            <img
              :src="
                rider !== undefined && rider.profileImage
                  ? rider.profileImage
                  : '/media/users/user1.jpg'
              "
              alt="user image"
            />
          </div>
          <div class="user-name" @click="showOverlay">
            {{ rider !== undefined && rider.name ? rider.name : "..." }}
          </div>
        </div>
        <div class="info-rating-block">
          <span class="info-heading">Rating</span>
          <div class="info">
            <span class="ratings-block">
              <i
                v-for="index in 5"
                class="fa fa-star"
                v-bind:class="getGoldClass(rating, index)"
                :key="index"
              ></i>
            </span>
            <span style="margin-left: 2.5px">{{ rating }}</span>
          </div>
        </div>
      </div>
      <div class="rider-info">
        <div class="info-blocks">
          <div class="info-block">
            <div class="info">
              <div class="heading">Baskytor ID#</div>
              <div class="text">
                {{
                  rider !== undefined && rider.employeeNumber != undefined
                    ? rider.employeeNumber
                    : "123-456"
                }}
              </div>
            </div>
            <div class="info">
              <div class="heading">Earnings</div>
              <div v-if="region === 'PAKISTAN'" class="text">
                PKR{{
                  rider !== undefined && rider.totalEarning
                    ? rider.totalEarning.toFixed(2)
                    : 0
                }}
              </div>
              <div v-if="region === 'US'" class="text">
                ${{
                  rider !== undefined && rider.totalEarning
                    ? rider.totalEarning.toFixed(2)
                    : 0
                }}
              </div>
            </div>
            <div v-if="region === 'PAKISTAN'" class="info">
              <div class="heading">CNIC</div>
              <div class="text">
                {{
                  rider !== undefined && rider.cnic ? rider.cnic : "361-03-4418"
                }}
              </div>
            </div>

            <div v-if="region === 'US'" class="info">
              <div class="heading">SSN</div>
              <div class="text">
                {{
                  rider !== undefined && rider.ssn ? rider.ssn : "361-03-4418"
                }}
              </div>
            </div>

            <div v-if="region === 'PAKISTAN'" class="info-block">
              <div class="info">
                <div class="heading">Last Seen</div>
                <div class="text">
                  <template v-if="rider.time.lastLocated">
                    <timeago :datetime="rider.time.lastLocated"></timeago>
                  </template>
                  <template v-else>
                    unknown
                  </template>
                </div>
              </div>
            </div>
          </div>

          <div v-if="region === 'PAKISTAN'" class="info-controls">
            <div class="fuel-mainblock">
              <div class="fuel">
                <h4 class="fuel-heading">Withdrawable</h4>
                <p class="fuel-text">PKR {{ rider.balance.withdrawable }}</p>
              </div>
              <div v-if="withdrawAmount !== 0">
                <div
                  v-on:click="withDrawClicked"
                  class="Fuel-amount-withdrawn-NotZero btn"
                >
                  PKR {{ withdrawAmount }} withdrawn
                </div>
              </div>
              <div v-else>
                <div
                  v-on:click="withDrawClicked"
                  class="Fuel-amount-withdrawn-zero btn"
                >
                  PKR {{ withdrawAmount }} withdrawn
                </div>
              </div>
            </div>
          </div>

          <div v-if="region === 'US'" class="info-block">
            <div class="info">
              <div class="heading">Bank Name</div>
              <div class="text">
                {{
                  rider !== undefined && rider.bankName ? rider.bankName : "..."
                }}
              </div>
            </div>
            <div class="info">
              <div class="heading">Acc. Title</div>
              <div class="text">
                {{
                  rider !== undefined && rider.accountTitle
                    ? rider.accountTitle
                    : "..."
                }}
              </div>
            </div>
            <div class="info">
              <div class="heading">Acc. No</div>
              <div class="text">
                {{
                  rider !== undefined && rider.accountNumber
                    ? rider.accountNumber
                    : "..."
                }}
              </div>
            </div>
          </div>
          <div class="info-controls">
            <span
              class="info-control edit"
              v-if="!isArchived"
              @click="editRider(index)"
              >Edit</span
            >
            <span
              class="info-control archive"
              @click="archiveRider(index)"
              v-if="!isArchived"
              >Archive</span
            >
            <span
              class="info-control unarchive"
              @click="unArchiveRider(index)"
              v-if="isArchived"
              >Unarchive</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="performance-block" v-bind:class="getOverlayClass()">
      <div class="performance-header">Performance</div>
      <div class="list-block shadow-sm">
        <ul class="details-list head">
          <li class="details-list-item">Orders</li>
          <li class="details-list-item">Avg. Order Del. Time</li>
          <li class="details-list-item">Zone</li>
        </ul>
        <ul class="details-list">
          <li class="details-list-item">
            {{
              rider !== undefined && rider.averageOrderTime
                ? rider.averageOrderTime.orders
                : "0"
            }}
          </li>
          <li class="details-list-item time">
            {{
              rider !== undefined && rider.averageOrderTime
                ? Math.round(
                    (parseFloat(rider.averageOrderTime.average) +
                      Number.EPSILON) *
                      100
                  ) /
                    100 +
                  " hours"
                : "..."
            }}
          </li>
          <li v-if="region === 'PAKISTAN'" class="details-list-item">
            {{
              rider !== undefined && rider.startingZone !== undefined
                ? rider.startingZone.alias
                : "0"
            }}
          </li>
          <li v-if="region === 'US'" class="details-list-item">
            {{
              rider !== undefined && rider.zipCodes
                ? rider.zipCodes.length
                : "0"
            }}
          </li>
        </ul>
      </div>
    </div>
    <div class="overlay-details" v-bind:class="getOverlayClass()">
      <div class="bg"></div>
      <div class="overlay-content">
        <div class="close-btn" @click="showOverlay">
          <i class="fa fa-times"></i>
        </div>
        <div class="rider-profile">
          <div class="user-block">
            <div class="user-image">
              <img
                :src="
                  rider !== undefined && rider.profileImage
                    ? rider.profileImage
                    : '/media/users/user1.jpg'
                "
                alt="user image"
              />
            </div>
            <div class="user-name">
              {{ rider !== undefined && rider.name ? rider.name : "..." }}
            </div>
          </div>
          <div class="info-rating-block">
            <div class="info">
              <span class="ratings-block">
                <i
                  v-for="index in 5"
                  class="fa fa-star"
                  v-bind:class="getGoldClass(rating, index)"
                  :key="index"
                ></i>
              </span>
              <span style="margin-left: 2.5px">{{ rating }}.0</span>
            </div>
          </div>
        </div>
        <div class="info-blocks">
          <div class="info-block-container">
            <div class="info-block">
              <div class="info">
                <div class="heading">Balance</div>
                <div v-if="region === 'PAKISTAN'" class="text">
                  PKR
                  {{
                    rider !== undefined && rider.myBalance != undefined
                      ? rider.myBalance
                      : 3
                  }}
                </div>

                <div v-if="region === 'US'" class="text">
                  $
                  {{
                    rider !== undefined && rider.myBalance != undefined
                      ? rider.myBalance
                      : 3
                  }}
                </div>
              </div>
              <div class="info">
                <div class="heading">Average Orders / Day</div>
                <div class="text">15</div>
              </div>
            </div>
            <div class="info-block">
              <div class="info">
                <div class="heading">Level</div>
                <div class="text">Beginner</div>
              </div>
            </div>
          </div>
          <div class="assets-block">
            <div class="assets-heading">Assets Issued</div>
            <div class="assets">
              <template v-if="rider !== undefined && rider.merchandise">
                <div
                  class="asset"
                  :key="index"
                  v-for="(item, index) in rider.merchandise"
                >
                  {{ item }}
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="region == 'PAKISTAN' && modalData !== null">
      <FuelWithdrawModal
        ref="fuelModal"
        :riderDetails="rider"
        @close="fuelModalClosed"
      />
      <!-- :fuelwithDrawDetails="modalData" -->
    </template>
  </div>
</template>

<script>
import FuelWithdrawModal from "@/view/pages/riders/FuelWithdrawModal.vue";
import { mapState } from "vuex";
import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";
export default {
  props: {
    isArchived: Boolean,
    rider: Object,
    index: Number
  },
  components: {
    FuelWithdrawModal
  },
  data() {
    return {
      timelastloc: null,
      overlayActive: false,
      rating: 4,
      withdrawAmount: this.rider.balance.requested,
      modalData: null,
      riderID: "aBC256"
    };
  },
  computed: {
    ...mapState({
      region: state => state.region.isRegion
    })
  },

  mounted() {
    if (
      this.rider !== undefined &&
      this.rider.avgCustomerRating !== undefined
    ) {
      this.rating =
        this.rider.avgCustomerRating % 1 != 0
          ? this.rider.avgCustomerRating
          : this.rider.avgCustomerRating + ".0";
    } else {
      this.rating = 0;
    }
  },
  methods: {
    ...mapGetters(["isRegion"]),
    withDrawClicked: function() {
      console.log("withdraw clicked");
      this.modalData = "it have Data";
      setTimeout(() => {
        this.$refs.fuelModal.showfuelModal();
      }, 100);
    },
    fuelModalClosed() {
      this.modalData = null;
      this.emit("update");
    },
    getGoldClass: function(total, key) {
      return key <= total ? "gold" : "";
    },
    showOverlay: function() {
      this.overlayActive = !this.overlayActive;
    },
    getOverlayClass: function() {
      return this.overlayActive ? "overlay-active" : "overlay-non-active";
    },
    editRider: function(index) {
      this.$emit("editRider", index);
    },
    archiveRider: async function(index) {
      await ApiService.post("/rider/archive", {
        riderId: this.rider._id,
        action: "archive"
      });
      this.isArchived = true;
      this.$emit("archiveRider", index);
    },
    unArchiveRider: async function(index) {
      await ApiService.post("/rider/archive", {
        riderId: this.rider._id,
        action: "unarchive"
      });
      this.isArchived = false;
      this.$emit("unArchiveRider", index);
    }
  }
};
</script>

<style lang="scss" scoped>
.single-rider {
  @media only screen and (max-width: 1366px) {
    width: 495px;
  }
  width: 525px;
  width: 100%;
  background: #fff;
  border-radius: 15px;
  border-top-left-radius: 0;
  position: relative;
  margin: 10px;
  overflow: hidden;

  .upper-block {
    transition: all 400ms;
    display: flex;
    padding: 15px;
    padding-top: 35px;
    position: relative;
    .approved-status {
      position: absolute;
      top: 0;
      left: 0;
      padding: 5px;
      border-bottom-right-radius: 5px;
      &.approved {
        background: #6dff4c;
        color: #fff;
      }
      &.non-approved {
        background: #f8b040;
        color: #fff;
      }
    }
    .rider-profile {
      width: 25%;
    }
    .rider-info {
      width: 75%;
      .info-blocks {
        display: grid;
        grid-template-columns: 1fr 1fr;
        .info-block {
          display: flex;
          flex-direction: column;
          padding: 2.5px 5px;
          .info {
            padding: 3.5px 0;
            display: flex;
            justify-content: space-between;
            .heading {
              font-size: 9px;
              width: 40%;
              font-weight: 600;
            }
            .text {
              font-size: 8px;
              width: 60%;
            }
          }
        }
      }
      .info-controls {
        display: flex;
        justify-content: flex-end;
        .info-control {
          padding: 5px;
          margin-right: 10px;
          width: 75px;
          text-align: center;
          cursor: pointer;
          border-radius: 20px;
          &.edit {
            background: #4ff86f;
            color: #fff;
          }
          &.archive {
            background: #f8b040;
            color: #fff;
          }
          &.unarchive {
            background: #3fa9fb;
            color: #fff;
          }
        }
      }
    }
    &.overlay-active {
      filter: blur(5px);
      pointer-events: none;
    }
  }
  .rider-profile {
    .user-block {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0;
      flex-direction: column;
      .user-image {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        img {
          height: 55px;
          width: 55px;
          border-radius: 50%;
        }
        .online-status {
          position: absolute;
          height: 18px;
          width: 18px;
          bottom: -3px;
          right: -2px;
          border: 3px solid #fff;
          border-radius: 50%;
          &.online {
            background: #00bc00;
          }
          &.offline {
            background: #d8102a;
          }
          &.away {
            background: orangered;
          }
        }
      }
      .user-name {
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 14px;
        cursor: pointer;
        color: #576ce0;
        text-align: center;
        &:hover {
          filter: brightness(0.9);
        }
      }
    }
    .info-rating-block {
      display: flex;
      flex-direction: column;
      .info-heading {
        font-size: 12px;
        text-transform: uppercase;
        color: #ddd;
        padding: 5px 0;
      }
      .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 14px;
        font-weight: 700;
        .ratings-block {
          i.gold {
            color: #ff9500;
          }
        }
      }
    }
  }

  .performance-block {
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    transition: all 400ms;
    .performance-header {
      background: #4e71e1;
      text-align: center;
      color: #fff;
      text-transform: capitalize;
      padding: 15px;
      font-size: 16px;
    }
    .list-block {
      width: 100%;
      margin-bottom: 15px;
      ul.details-list {
        list-style: none;
        width: 100%;
        padding: 10px;
        display: flex;
        margin-bottom: 0;
        &.head {
          font-weight: 700;
        }
        li.details-list-item {
          &:nth-child(1) {
            width: 25%;
          }
          &:nth-child(2) {
            width: 60%;
            &.time {
              padding-left: 60px;
            }
          }
          &:nth-child(3) {
            width: 15%;
          }
        }
      }
    }
    &.overlay-active {
      filter: blur(5px);
      pointer-events: none;
    }
  }
  .overlay-details {
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    border-radius: 15px;
    border-top-left-radius: 0;
    transform: translateY(-100%);

    transition: all 400ms;
    overflow: hidden;
    .bg {
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(255, 255, 255, 0);
      height: 100%;
      width: 100%;
    }
    .overlay-content {
      z-index: 3;
      width: 100%;
      padding: 15px;
      transform: translateZ(0);
      background: #d8102a;
      position: relative;
      transform: translateY(-100%);
      transition: all 400ms;
      display: flex;
      .close-btn {
        position: absolute;
        top: 15px;
        right: 15px;
        height: 20px;
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #fff;
        cursor: pointer;
        i {
          color: #000;
        }
      }
      .rider-profile {
        width: 25%;
        .user-block {
          .user-name {
            color: #fff;
          }
        }
        .info-rating-block {
          .info {
            color: #fff;
          }
        }
      }

      .info-blocks {
        width: 75%;
        .info-block-container {
          display: grid;
          grid-template-columns: 1fr 1fr;
          .info-block {
            font-size: 12px;
            display: flex;
            flex-direction: column;
            padding: 2.5px 5px;
            .info {
              padding: 3.5px 0;
              display: flex;
              justify-content: space-between;
              color: #fff;
              .heading {
                width: 60%;
                font-weight: 600;
              }
              .text {
                width: 40%;
              }
            }
          }
        }
        .assets-block {
          padding: 10px 0;
          .assets-heading {
            color: #fff;
            padding: 5px 0;
          }
          .assets {
            display: flex;
            flex-wrap: wrap;
            .asset {
              padding: 5px;
              margin-right: 10px;
              min-width: 75px;
              text-align: center;
              background: #fff;
              color: #000;
              pointer-events: none;
            }
          }
        }
      }
    }
    &.overlay-active {
      transform: translateY(0);
      .overlay-content {
        transform: translateY(0);
      }
      .bg {
        z-index: 2;
        background: rgba(255, 255, 255, 0.5);
      }
    }
  }
  .fuel-mainblock {
    .fuel {
      display: flex;
      flex-direction: row;
      margin-right: 35px;
      .fuel-heading {
        font-weight: 500;
        font-size: 14px;
        margin: 5px;
      }
      .fuel-text {
        font-weight: 200;
        font-size: 12px;
        margin: 5px;
      }
    }
    .Fuel-amount-withdrawn-zero {
      padding: 3px;
      color: #809900;
      background: #ffef5d;
      border-radius: 15px;
      font-weight: 700;
      display: flex;
      justify-content: center;
      width: 100%;
    }
    .Fuel-amount-withdrawn-NotZero {
      padding: 3px;
      color: #e90008;
      background: #ffafb1;
      border-radius: 15px;
      font-weight: 700;
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
}
</style>
